import './App.css';
import { useRef, useState } from 'react';
import ModelViewer from './Components/ModelViewer/ModelViewer';
import Onboarding from './Components/Onboarding/Onboarding'
import MuteToggleButton from './Components/MuteToggleButton/MuteToggleButton';
import ARButton from './Components/ARButton/ARButton';
import QRCodeModal from './Components/QRCodeModal/QRCodeModal';



function App() {
  const audioPlayer = useRef(document.querySelector(".audio-player"));
  const [helpScreenVisible, setHelpScreenVisible] = useState(true);
  const [qrCodeModalVisible, setQRCodeModalVisible] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const toggleMute = () => {
    logToggleMuteButtonClicked();
    setIsMuted(!isMuted);
    audioPlayer.current.muted = !isMuted;
  }
  
  const logStartButtonClicked = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'start_experience'});
  }
  const logArButtonClickedDesktop = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'ar_button_desktop'});
  }
  const logArButtonClickedMobile = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'ar_button_mobile'});
  }
  const logToggleMuteButtonClicked = () => {
    //eslint-disable-next-line
    gtag('event', 'click', {'event_category': 'button', 'event_label': 'toggle_mute_button'});
  }

  const playAudio = () => {
    audioPlayer.current.play();
  }
  
  return (
    <div className="App">
      <audio ref={audioPlayer} src="./media/MC_AudioMASTER_Anthem2021_Christmas_0dBFS-1.mp3" loop={true} className="hidden audio-player"></audio>
      <ModelViewer/>
      <MuteToggleButton isMuted={isMuted} toggleMute={toggleMute}/>
      <ARButton 
      setQRCodeModalVisible={setQRCodeModalVisible}
      logArButtonClickedDesktop={logArButtonClickedDesktop} 
      logArButtonClickedMobile={logArButtonClickedMobile}
      ></ARButton>
      {qrCodeModalVisible && <QRCodeModal setQRCodeModalVisible={setQRCodeModalVisible}></QRCodeModal>}
      {helpScreenVisible && <Onboarding
      setHelpScreenVisible={setHelpScreenVisible} 
      logStartButtonClicked={logStartButtonClicked} 
      playAudio={playAudio}/>}
    </div>
  );
}

export default App;
