import './ModelViewer.css';

const ModelViewer = () => {
    return (<iframe
        title="reydar-viewer"
        className="reydar-viewer-frame"
        frameBorder="0"
        allowFullScreen=""
        mozallowfullscreen="true"
        webkitallowfullscreen="true"
        allow="autoplay; fullscreen; xr-spatial-tracking; clipboard-write;"
        xr-spatial-tracking=""
        execution-while-out-of-viewport=""
        execution-while-not-rendered=""
        web-share=""
        width="100%"
        height="100%"
        src="https://reybeam.reydar.com/?config_uuid=de6f4238-1992-4ae4-b435-ba8f6f2ed427"
      ></iframe>);
}
 
export default ModelViewer;