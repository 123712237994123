import "./QRCodeModal.css";
import { ReactComponent as QRSurround } from "./qr-surround.svg";
import { QRCodeSVG } from "qrcode.react";

const QRCodeModal = (props) => {
    const url = "https://mastercard.enginecloud.co.uk/?handoff";


  return (
    <div className="qr-code-modal">
      <div className="qr-code-modal-box">
        <div className="btn-close-bar">
            <svg className="btn-close" onClick={() => props.setQRCodeModalVisible(false)} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24 8L8 24" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 8L24 24" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </div>
        <div className="qr-code-modal-container">
          <img
            src="./media/Mastercard-logo-no-wordmark.png"
            className="mastercard-logo"
            alt="Mastercard logo"
          />
          <p className="onboarding-title">Scan QR Code to view in your space</p>
          <div className="qr-code-modal__qr">
            <QRSurround className="qr-surround">
              <div
                className="qr-surround-box"
                style={{ "background-color": "red" }}
              ></div>
            </QRSurround>
            <QRCodeSVG value={url} size={80} className="qr-code" />
          </div>
          <div className="qr-code-text-block">
            <div>
                <p className="qr-code-text-subtitle">Hand off to mobile</p>
            </div>
            <div>
                <p className="qr-code-text">Use your mobile device camera</p>
                <p className="qr-code-text">to scan the QR code above</p>
            </div>
            <div>
                <p className="qr-code-text-footer">Available only on devices that support ARKit and AR Core.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRCodeModal;
