import "./PageDots.css"
const PageDots = (props) => {
    return (
    <div className="page-dots-container">
     {props.onboardingContents.map((item, index) => {
        return(
        index === props.pageIndex ? 
        <div className="page-dot page-dot-active" key={index}></div> :
        <div className="page-dot" key={index}></div>     
    )})}
    </div>);
}
 
export default PageDots;