import './MuteToggleButton.css';

const MuteToggleButton = (props) => {
    return (
        props.isMuted ? (<svg onClick={() => props.toggleMute()} alt='Volume icon' className='btn-toggle-mute' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6666 6.66675L7.99996 12.0001H2.66663V20.0001H7.99996L14.6666 25.3334V6.66675Z" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30.6666 12L22.6666 20" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22.6666 12L30.6666 20" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>):(<svg onClick={() => props.toggleMute()} alt='Volume icon' className='btn-toggle-mute' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6666 6.66675L7.99996 12.0001H2.66663V20.0001H7.99996L14.6666 25.3334V6.66675Z" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M25.4266 6.57324C27.9263 9.07361 29.3305 12.4644 29.3305 15.9999C29.3305 19.5354 27.9263 22.9262 25.4266 25.4266M20.72 11.2799C21.9698 12.5301 22.6719 14.2255 22.6719 15.9932C22.6719 17.761 21.9698 19.4564 20.72 20.7066" stroke="#F1EFEB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>) 
     );
}
 
export default MuteToggleButton;